a.link{
    position: relative;
    display: block;
    padding: 4px 0;
    font-family: Lato, sans-serif;
    color: #ecf0f1;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
}

a.link::after {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    background: #F07645;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s;
}

a.link:hover {
    color: #95a5a6;
}

a.link:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

a.active::after {
    transform: scaleX(1);
}
